/* Start Scroll*/
::-webkit-scrollbar-thumb {
    background-color: #170466;
}

::-webkit-scrollbar-track {
    background-color: #d6dce5;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Start Menu*/
.left-sidenav-menu li>a:hover,
.left-sidenav-menu li>a:hover i,
.left-sidenav-menu li ul li>a:hover,
.left-sidenav-menu li ul li>a:hover i,
.left-sidenav-menu li.mm-active>a,
.left-sidenav-menu li.mm-active .mm-active>a {
    color: #170466;
    font-weight: bold;
}

.left-sidenav {
    border-right: 1px solid #c9d6e7;
}

.navbar-custom {
    border-bottom: 1px solid #c9d6e7;
}

.card .card-header {
    border-bottom: 1px solid #c9d6e7;
}

.card {
    border: 1px solid #c9d6e7;
}

.left-sidenav hr.hr-dashed.hr-menu {
    border-color: #c9d6e7;
}

.pixel {
    background-color: #d7d7d7;
}

.file-box-content .file-box {
    border: 1px solid #b6bdc7;
    margin-right: 13px;
    margin-bottom: 20px;
}

hr {
    border-color: #c9d6e7;
}

.select2-container *:focus,
select.form-select:focus,
select.form-select:focus+.select2-container,
input.form-control:focus {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(134, 183, 254, 1);
    -moz-box-shadow: 0px 0px 5px 2px rgba(134, 183, 254, 1);
    box-shadow: 0px 0px 5px 2px rgba(134, 183, 254, 1);
}

.same-disabled,
.same-disabled:focus {
    background-color: var(--bs-secondary-bg);
    opacity: 1;
    pointer-events: none;
}

.page-wrapper .page-content {
    min-height: calc(100vh - 106px);
    padding: 0 8px 8px 8px;
}

/* .table-scroll tbody {
    display: block;
    max-height: 200px;
    overflow-y: auto;
}

.table-scroll tr {
    display: table;
    width: 100%;
    Asegura que las celdas tengan un ancho fijo
    table-layout: fixed;
} */

.table-scroll {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    /* Fija el ancho de las columnas */
}

.table-scroll thead {
    display: table;
    width: 100%;
}

.table-scroll tbody {
    display: block;
    max-height: 320px;
    overflow-y: auto;
}

.table-scroll tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-scroll th,
.table-scroll td {
    box-sizing: border-box;
    text-align: left;
}

.table-scroll th {
    position: sticky;
    top: 0;
    z-index: 2;
    /* Mantiene el encabezado encima del contenido */
}

/* Ajuste específico para la última columna */
.table-scroll th:last-child,
.table-scroll td:last-child {
    width: 50px;
    /* Ancho fijo para la última columna */
    text-align: center;
    /* Opcional: Centrar contenido */
}

/* indispensable por el overflow del select2 */
.page-wrapper {
    overflow: hidden;
}