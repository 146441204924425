.select2 {
    border: 1px solid var(--bs-border-color);
    border-radius: var(--bs-border-radius-sm);
    padding-top: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.select2-dropdown {
    border: 1px solid var(--bs-border-color);
}

.select2-results__option:hover {
    background-color: rgb(213 213 217 / 50%);
    color: #1d2c48;
}

.select2-results {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.select2-selection.select2-selection--multiple {
    display: flex;
    min-height: 30px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: flex;
    margin-bottom: 0;
    margin-top: 2px;
    overflow: auto;
    align-items: center;
}

.select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    display: flex;
    margin-right: 1rem;
}

.select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    margin-left: 5px;
    margin-right: 5px;
    height: 17px;
    width: 17px;
    border-radius: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #170466;
    color: white;
}

.select2-container .select2-search--inline .select2-search__field {
    width: 100% !important;
    margin-top: 0;
    height: 100%;
    margin-left: 0;
}

.select2-container--disabled {
    background-color: rgba(155, 167, 202, 0.08) !important;
    color: #9099b6 !important;
}

.select2-container .select2-selection--single {
    display: flex;
    align-items: center;
}

.select2-container .select2-selection--single .select2-selection__clear {
    font-size: 1.3em;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 6px;
}

.select2.select2-container.select2-container--Bootstrap5.select2-container--focus.select2-container--below.select2-container--open .select2-search.select2-search--inline:has(.select2-search__field:focus) {
    z-index: 1;
    padding-top: 1px;
}

.select2.select2-container.select2-container--Bootstrap5.select2-container--focus.select2-container--below.select2-container--open .select2-search.select2-search--inline:has(.select2-search__field:not(:focus)) {
    z-index: -1;
}

.select2-search.select2-search--inline {
    width: 99%;
    position: absolute;
    border: 0;
    top: 0;
    bottom: 0 !important;
    height: 98%;
    padding-top: 5px;
    z-index: -1;
}

.select2-container .select2-selection--multiple {
    border: 0;
}