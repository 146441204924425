/*****************************/
/* Correcciones SweetAlert2 */
/***************************/

.swal2-popup {
    border: 1px solid #d9d9d9;
    padding: 0.5rem;
}

.swal2-popup .swal2-html-container {
    margin: 0.5rem 0 !important;
}

.swal2-popup.swal2-toast .swal2-icon {
    font-size: 11px;
    margin-left: 0.3rem;
    margin-right: 0.7rem;
}

.swal2-popup.swal2-toast .swal2-close {
    font-size: 2rem;
    align-self: start;
}